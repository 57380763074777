.section {
    margin: 0 auto;
    max-width: 768px;
    width: 100%;
}

.main {
    padding: 16px;
    text-align: center;
}

.main h2 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .125rem;
    line-height: 1.9;
    text-align: center;
}

.main h3 {
    color: #53B5B0;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: .125rem;
    line-height: 1.9;
    text-align: center;
}

.main p {
    letter-spacing: .1rem;
    line-height: 1.7;
    text-align: left;
}

.main img+h2, img+h3, img+p, h2+p, h3+p, span+h3 {
    margin-top: 16px;
}

.main .row {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.main .row .column {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-right: 16px;
    max-width: 105px;
}

.main .row .column img {
    max-height: 105px;
    height: 100%;
    width: 100%;
}

.main .row .column:last-child {
    margin-right: 0;
}

.main .row .column p {
    font-size: 14px;
    font-weight: 600;
    text-align: center;
}

.safety {
    padding: 16px;
    text-align: center;
}

.safety h3 {
    font-size: 18px;
    font-weight: 600;
    letter-spacing: .125rem;
    line-height: 1.9;
    text-align: center;
}

.safety p {
    letter-spacing: .1rem;
    line-height: 1.7;
    text-align: left;
}

.safety .row {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
}

.safety .row p {
    font-weight: 600;
    margin: 0 0 0 16px;
}

.step__label {
    background: #53b5b0;
    border-radius: 4px;
    color: #fff;
    display: block;
    font-size: 14px;
    font-weight: 600;
    margin: 0 auto;
    line-height: 30px;
    height: 30px;
    width: 100px;
}

.main .steps {
    align-items: flex-start;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
}

.main .steps > a {
    align-items: center;
    display: flex;
    flex-flow: column;
    justify-content: center;
    margin-right: 8px;
    text-decoration: none;
    text-align: center;
}

.main .steps > a:last-child {
    margin-right: 0;
}

.main .steps > a:hover {
    text-decoration: none;
}

.main .steps > a:visited {
    text-decoration: none;
}

.main .steps .label {
    background: #53b5b0;
    border-radius: 4px;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 8px;
}

.main .steps .heading {
    color: #000;
    font-size: 14px;
    margin: 8px 0;
    text-align: center;
}

.main .steps .heading:hover {
    color: #000;
}

.main .steps .triangle {
    border-bottom: 1px solid #5F5F5F;
    border-right: 1px solid #5F5F5F;
    height: 12px;
    width: 12px;
    transform: rotate(45deg);
}