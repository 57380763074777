/* text and headline */
.content {
    margin: 0 auto;
    padding: 32px 16px;
    max-width: 768px;
    width: 100%;
}

.content p {
    letter-spacing: 0.05em;
    line-height: 1.9;
    margin-top: 16px;
}

.content h1, .content h2, .content h3, .content h4, .content h5, .content h6 {
    line-height: 1.4;
    letter-spacing: 0.05em;
    padding: 0;
    margin-top: 16px;
}

.content h1 {
    color: #53B5B0;
    font-size: 24px;
    font-weight: 600;
    text-align: center;
}

.content h2 {
    background-color: #53B5B0;
    color: #fff;
    font-size: 18px;
    padding: 8px;
}

.content h3 {
    border-bottom: 3px solid #53B5B0;
    font-size: 16px;
    padding: 4px 8px;
}

.content h4 {
    color: #53B5B0;
    font-size: 16px;
    font-weight: 600;
    padding: 4px 0;
}

.content ul {
    margin-top: 16px;
    margin-left: 20px;
}

.content ol {
    margin-top: 16px;
    margin-left: 20px;
}

.content ul > li {
    line-height: 1.7;
}

.content ol > li {
    line-height: 1.7;
}