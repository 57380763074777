.square {
    margin: 0 auto;
    position: relative;
    width: 25%;
    overflow: hidden;
}
.square::before {
    content: "";
    display: block;
    padding-top: 100%;
}
.square img {
    position: absolute;
    object-fit: contain;
    object-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 192px;
    bottom: 0;
    margin: auto;
}

.squareSub {
    position: relative;
    width: 25%;
    overflow: hidden;
}
.squareSub::before {
    content: "";
    display: block;
    padding-top: 100%;
}
.squareSub img {
    position: absolute;
    object-fit: contain;
    object-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    max-height: 156px;
    bottom: 0;
    margin: auto;
}

.rectangle {
    position: relative;
    width: 100%;
    overflow: hidden;
}

.rectangle::before {
    content: "";
    display: block;
    padding-top: 56.25%;
}

.rectangle img {
    position: absolute;
    object-fit: cover;
    object-position: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}
