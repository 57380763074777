.spacer-xsmall {
    height: 12px;
}
.spacer-small {
    height: 20px;
}
.spacer-medium {
    height: 32px;
}
.spacer-large {
    height: 48px;
}
.spacer-xlarge {
    height: 48px;
}

.widthSpacer-xsmall {
    width: 4px;
}
.widthSpacer-small {
    width: 8px;
}
.widthSpacer-medium {
    width: 16px;
}
.widthSpacer-large {
    width: 32px;
}
.widthSpacerxlarge {
    width: 64px;
}


@media (min-width: 600px) {
    .spacer-xsmall {
        height: 20px;
    }
    .spacer-small {
        height: 32px;
    }
    .spacer-xlarge {
        height: 60px;
    }
}

@media (min-width: 1024px) {
    .spacer-medium {
        height: 48px;
    }
    .spacer-large {
        height: 60px;
    }
    .spacer-xlarge {
        height: 80px;
    }
}

