.heading {
    color: #444;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
}

.title {
    color: #53B5B0;
    font-size: 24px;
    font-weight: 600;
}

.subTitle {
    color: #53B5B0;
    font-size: 18px;
    font-weight: 600;
}

.center {
    align-items: center;
    display: flex;
    flex-flow: row nowrap;
    justify-content: center;
    margin: auto;
    text-align: center;
}

.flexRowNowrap {
    display: flex;
    flex-flow: row nowrap;
}

.displayOnlySm {
    display: block;
}

@media screen and (min-width: 576px) {
    .displayOnlySm {
        display: none;
    }
}

